import React from "react";
import { graphql, Link } from "gatsby";

import CarouselLayout from "../components/carousel-layout";
import SEO from "../components/seo";

export const query = graphql`
  query CycleContextPageTemplateQuery($id: String!) {
    cycle: sanityCycle(id: { eq: $id }) {
      id
      title
      theme
      _rawPageContent(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
      publishedAt
    }
  }
`;

const CycleContextPageTemplate = ({ data }) => {
  const cycle = data?.cycle;
  if (!cycle) return null;
  return (
    <>
      <SEO
        title={`${cycle.title}: ${cycle.theme}`}
        description={cycle.description}
      />
      <CarouselLayout
        title={
          <Link to="..">
            <h1>{cycle.theme}</h1>
          </Link>
        }
        blocks={cycle._rawPageContent}
      />
    </>
  );
};

export default CycleContextPageTemplate;
